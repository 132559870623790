.main-player {
  height: 170px;
  @include media-breakpoint-up(lg) {
    height: 85px;
  }
  background-color: $dark;
  color: $white;
  width: 100vw;
  position: relative;

  .play-control {
    align-items: center;

    .backwards-control {
      border-radius: 50%;
      width: 25px;
      height: 25px;
      box-shadow: 10px 10px 40px 0px #0D0E0F;
      box-shadow: -10px -10px 40px 0px #40464E;
      text-align: center;

      i {
        vertical-align: middle;
        margin: auto;
      }
    }

    .play-stop-control {
      width: 65px;
      height: 65px;
      border-radius: 50%;
      box-shadow: 10px 10px 40px 0px #0D0E0F;
      box-shadow: -10px -10px 40px 0px #40464E;
      margin-left: 10px;
      margin-right: 10px;

      i {
        vertical-align: middle;
        margin: auto;
      }
    }
  }

  .track-info {
    padding-left: 20px;
  }

  .wavesurfer-container {
    //padding-top: 30px;
    //padding-bottom: 30px;
  }

  .timer-volume-container {

    * {
      display: inline-block;
      vertical-align: middle;
    }

    .volume-range {
      * {
        display: inline-block;
        vertical-align: middle;
      }

      .volume-range-input {
        width: 100px;
      }
    }
  }

  .player-control-track-info-container {
    * {
      display: inline-block;
      vertical-align: middle;
    }

    .player-control {
      * {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  .player-close {
    position: absolute;
    top: 10px;
    right: 10px;
  }

}
