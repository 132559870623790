.plans-order-control {
  min-height: 44px;
}

app-plans-edit {
  display: block;

  .plan-list-item-legend, .plan-list-item {
    display: grid;
    grid-template-columns: 1fr 1fr 70px 70px 1fr 90px;
    grid-template-rows: 1fr;
    grid-template-areas:'label name active price length created';

    .label, .name, .active-subs, .price, .length, .created, .extended-name, .link {
      padding-left: map-get($spacers, 2);
      padding-right: map-get($spacers, 2);
    }

    .label {
      grid-area: label;
    }

    .name {
      grid-area: name;
    }

    .active-subs {
      grid-area: active;
    }

    .price {
      grid-area: price;
    }

    .length {
      grid-area: length;
    }

    .created {
      grid-area: created;
    }
  }

  .plan-list-item-legend {
    .label, .name, .active-subs, .price, .length, .created, .extended-name, .link {
      text-transform: uppercase;
      font-weight: 400;
      font-size: 14px;
    }
  }

  .plan-list-item {
    cursor: pointer;
    .label, .name, .active-subs, .price, .length, .created {
      font-weight: 500;
      padding-top: map-get($spacers, 3);
      padding-bottom: map-get($spacers, 3);
    }
  }
}
