.col-sidebar {
  width: 250px;
}

.col-content {
  width: calc(100% - 250px);
}

nav {
  background-color: $gray-100;
  position: relative;

  ul {
    list-style: none;

    li {

      a {
        display: block;
        text-decoration: none;
        color: #8c9199;
        padding: 0 map-get($spacers, 2);

        &.active {
          background: linear-gradient(90deg, rgba(255, 116, 44, 0) 0%, #FF742C 0.01%, #FF742C 39.21%, rgba(255, 116, 44, 0.14) 75.88%, rgba(255, 116, 44, 0) 97.78%);
          border-radius: 3px;
          color: $white;
        }
      }
    }
  }

  i {
    color: #8c9199;
  }

  .collapse-button {
    border: none;
    background: transparent;
  }

  .copyright {
    position: absolute;
    bottom: 0;
    text-align: center;
    left: 0;
    color: #8c9199;
    width: 100%;
    padding: map-get($spacers, 3) 0;
  }
}
