.track-collection-filter {
  overflow-y: scroll;
  max-height: 100vh;

  accordion-group {
    background: none;
    border: none;
    .panel.card {
      background: none;
      border: none;
      .panel-heading {
        max-height: 49px;
        background: none;
        border: none;
        padding-left: map-get($spacers, 2);
        padding-right: map-get($spacers, 3);
        button.trigger-accordion-btn {
          max-height: 33px;
          .title {
            font-size: $font-size-base  * 1.045;
            font-weight: $font-weight-bold;
          }
        }
      }

      .panel-body.card-block.card-body {
        padding: map-get($spacers, 2);
      }
    }
  }
  .track-collection-filter-spacer {
    height: 6px;
    background: #EBECF0;
    box-shadow: inset -2px -2px 6px #FFFFFF, inset 2px 2px 3px #BDC1D1;
    border-radius: map-get($spacers, 2);
  }
  .track-collection-filter-legends-body {
    width: 17px;
    height: 38px;
    display: inline-block;
    position: relative;
    .track-collection-filter-legends-text {
      transform: rotate(-90deg);
      position: absolute;
      top: 12px;
      left: -12px;
      display: block;
      font-weight: $font-weight-light;
      color: $text-muted;
      font-size: calc(0.75 * $font-size-base);
    }
  }
  .track-collection-filter-category-body {
    overflow: visible;
  }
  .track-collection-filter-duration-body {
    .preset-duration-button {
      box-shadow: -7px -7px 16px #F5F5F5, 4px 3px 19px #C7CBDB, inset -1px -1px 16px #F5F6FA, inset 1px 1px 16px #E9EAF2;
      border-radius: map-get($spacers, 3);
    }
    .track-collection-filter-duration-slider {
      .ngx-slider {
        width: 100%;
        margin: 35px 0 45px;

        .ngx-slider-bar {
          background: #EBECF0;
          box-shadow: inset -2px -2px 6px #FFFFFF, inset 2px 2px 3px #BDC1D1;
          border-radius: 40px;
          height: 8px;
        }

        .ngx-slider-selection {
          background: $primary;
        }

        .ngx-slider-pointer {
          width: 29px;
          height: 18px;
          top: auto; /* to remove the default positioning */
          bottom: -8px;
          background: linear-gradient(147.42deg, rgba(64, 72, 93, 0.4) 6.32%, rgba(96, 106, 130, 0.4) 92.25%), linear-gradient(128deg, #B4B5BB -79.65%, #D9DADC 151.25%);
          background-blend-mode: soft-light, normal;
          box-shadow: -7px -7px 16px #FAFBFC, 4px 3px 19px #9599A9, inset -1px -1px 16px #F5F6FA, inset 1px 1px 16px #E9EAF2;
          border-radius: 40px;
        }

        .ngx-slider-pointer:after {
          display: inline-block;
          position: absolute;
          height: 0.4rem;
          width: 0.8rem;
          border-bottom: 0.125rem solid #000;
          border-bottom-color: #f7f7f7;
          content: '';
          border-radius: 0.1px;
          rotate: 90deg;
          left: 16px;
          background: none;
          top: 6px;
        }

        .ngx-slider-pointer:before {
          position: absolute;
          height: 0.4rem;
          width: 0.8rem;
          border-top: 0.125rem solid #000;
          border-top-color: #f7f7f7;
          border-bottom: 0.125rem solid #000;
          border-bottom-color: #f7f7f7;
          display: inline-block;
          border-radius: 0.1px;
          content: '';
          rotate: 90deg;
          left: 6px;
          top: 6px;
        }

        .ngx-slider-bubble {
          bottom: 14px;
        }

        .ngx-slider-limit {
          display: none;
        }

        .ngx-slider-tick {
          background: none;
          border-radius: 0;
          top: 15px;
          margin: 0;
        }

        .ngx-slider-ticks {
          margin-left: 8px;
        }
      }
    }
  }

  .track-collection-filter-tempo-slider {
    min-height: 70px;
    .ngx-slider {
      width: 100%;
      margin: 35px 0 45px;

      .ngx-slider-bar {
        background: #EBECF0;
        box-shadow: inset -2px -2px 6px #FFFFFF, inset 2px 2px 3px #BDC1D1;
        border-radius: 40px;
        height: 8px;
      }

      .ngx-slider-selection {
        background: $primary;
      }

      .ngx-slider-pointer {
        width: 29px;
        height: 18px;
        top: auto; /* to remove the default positioning */
        bottom: -8px;
        background: linear-gradient(147.42deg, rgba(64, 72, 93, 0.4) 6.32%, rgba(96, 106, 130, 0.4) 92.25%), linear-gradient(128deg, #B4B5BB -79.65%, #D9DADC 151.25%);
        background-blend-mode: soft-light, normal;
        box-shadow: -7px -7px 16px #FAFBFC, 4px 3px 19px #9599A9, inset -1px -1px 16px #F5F6FA, inset 1px 1px 16px #E9EAF2;
        border-radius: 40px;
      }

      .ngx-slider-pointer:after {
        display: inline-block;
        position: absolute;
        height: 0.4rem;
        width: 0.8rem;
        border-bottom: 0.125rem solid #000;
        border-bottom-color: #f7f7f7;
        content: '';
        border-radius: 0.1px;
        rotate: 90deg;
        left: 16px;
        background: none;
        top: 6px;
      }

      .ngx-slider-pointer:before {
        position: absolute;
        height: 0.4rem;
        width: 0.8rem;
        border-top: 0.125rem solid #000;
        border-top-color: #f7f7f7;
        border-bottom: 0.125rem solid #000;
        border-bottom-color: #f7f7f7;
        display: inline-block;
        border-radius: 0.1px;
        content: '';
        rotate: 90deg;
        left: 6px;
        top: 6px;
      }

      .ngx-slider-bubble {
        bottom: 14px;
      }

      .ngx-slider-limit {
        display: none;
      }

      .ngx-slider-tick {
        background: none;
        border-radius: 0;
        top: 15px;
        margin: 0;
      }

      .ngx-slider-ticks {
        margin-left: 8px;
      }
    }
  }

  .concat-label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 70%;

    label {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      vertical-align: bottom;
    }
  }

}
