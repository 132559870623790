app-plans-list-item {
  display: block;

  .plan-list-item-legend, .plan-list-item {
    display: grid;
    grid-template-columns: 1fr 1fr 70px 70px 80px 90px 1fr 50px;
    grid-template-rows: 1fr 4px;
    grid-template-areas:
      'label name active price length created extended-name link'
      'spacer spacer spacer spacer spacer spacer spacer spacer';

    .label, .name, .active-subs, .price, .length, .created, .extended-name, .link {
      padding-left: map-get($spacers, 2);
      padding-right: map-get($spacers, 2);
    }

    .label {
      grid-area: label;
    }

    .name {
      grid-area: name;
    }

    .active-subs {
      grid-area: active;
    }

    .price {
      grid-area: price;
      max-width: 100px;
    }

    .length {
      grid-area: length;
    }

    .created {
      grid-area: created;
    }

    .extended-name {
      grid-area: extended-name;
    }

    .link {
      grid-area: link;
      text-align: end;
    }

    .spacer {
      grid-area: spacer;
      box-shadow: 2px 2px 2px 0px #9FA3B3 inset, -2px -2px 6px 0px #FFFFFF inset;
      border-radius: 10px;
    }

    .active-subs, .price, .length, .created {
      text-align: center;
    }
  }

  .plan-list-item-legend {
    .label, .name, .active-subs, .price, .length, .created, .extended-name, .link {
      text-transform: uppercase;
      font-size: 14px;
    }
  }

  .plan-list-item {
    cursor: pointer;
    .label, .name, .active-subs, .price, .length, .created, .extended-name, .link {
      padding-top: map-get($spacers, 3);
      padding-bottom: map-get($spacers, 3);
    }
  }
}

