// basics
@import 'common/fonts';
@import 'variables';

// framework
@import 'bootstrap/bootstrap';

// libraries
//@import '~ngx-bootstrap/datepicker/bs-datepicker';

@import 'custom_toastr_alerts';
@import "additional";
@import 'global';

@import "/src/app/modules/auth-area/auth-area.component";
@import "/src/app/modules/auth-area/tracks/tracks-add/tracks-add.component";
@import "/src/app/modules/auth-area/tracks/tracks-edit/tracks-edit.component";
@import '/src/app/modules/shared/components/table/table.component';
@import 'src/app/modules/player/player.component';
@import 'src/app/modules/auth-area/plans/plans-edit/plans-edit.component';
@import 'src/app/modules/auth-area/playlists/playlists-edit/playlists-edit.component';
@import 'src/app/modules/shared/components/artist-selector/artist-selector.component';
@import 'src/app/modules/shared/components/user-selector/user-selector.component';
@import 'src/app/modules/shared/components/track-item/track-item.component';
@import 'src/app/modules/shared/components/playlist-selector/playlist-selector.component';
@import 'src/app/modules/auth-area/tracks/tracks-index/tracks-index.component';
@import 'src/app/modules/auth-area/tracks/tracks-index/tracks-index-filter/tracks-index-filter.component';
@import 'src/app/modules/shared/components/custom-tag-input/custom-tag-input.component';
@import 'src/app/modules/auth-area/import/track-file-states-index/track-file-states-index.component';
@import 'src/app/modules/auth-area/users/users-edit/users-edit.component';
@import 'src/app/modules/shared/components/form-group/form-group.component';
@import 'src/app/modules/login/login.component';
@import "src/app/modules/auth-area/plans/plans-list-item/plans-list-item.component";
@import "src/app/modules/shared/components/list-paginator/list-paginator.component";


.form-control.ng-touched.ng-valid:focus,
.form-control.ng-touched.ng-invalid:focus {
  box-shadow: none;
  outline: none;
}

