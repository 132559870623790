app-list-paginator {
  li {

    &.active {
      a {
        z-index: 3;
        color: $primary !important;
        font-weight: $font-weight-bold;
      }
    }

    a {
      background: transparent !important;
      position: relative;
      border: none !important;
      padding: .375rem .75rem !important;
      display: block;
      color: $body-color;
      text-decoration: none;
      transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
  }
}
