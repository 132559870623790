.track-collection-file-states-table {
  .track-collection-file-states-table-row {
    min-height: 30px;
    display: grid;
    grid-template-areas: 'dfcnumber name state hasmain';

    &.active {
      grid-template-areas: 'dfcnumber name state hasmain' 'tracks tracks tracks tracks';
    }

    .track-collection-file-states-table-column {

      &.dfcnumber {
        grid-area: dfcnumber;
      }

      &.name {
        grid-area: name;
      }

      &.state {
        grid-area: state;
      }

      &.hasmain {
        grid-area: hasmain;
      }

      &.tracks {
        grid-area: tracks;

        .track-file-states-table {
          .track-file-states-table-row {
            display: grid;
            grid-template: 'name sd waveform watermarked duration';

            .track-file-states-table-col {

              &.name {
                grid-area: name;
              }

              &.sd {
                grid-area: sd;
              }

              &.waveform {
                grid-area: waveform;
              }

              &.watermarked {
                grid-area: watermarked;
              }

              &.duration {
                grid-area: duration;
              }
            }
          }
        }
      }
    }
  }
}
