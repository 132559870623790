app-users-edit {
  .user-edit-container {
    display: grid;
    grid-template-columns: 75% 25%;
    grid-template-areas:"details utility";

    .details { grid-area: details; }
    .utility { grid-area: utility; }

    .fancy-container {
      border-radius: 8px;
      border: 1px solid $gray-200;
      background: $white;
      box-shadow: 0 4px 8px 0 opacify($gray-400, 0.3);
      padding: 20px;
    }

    .fancy-spacer {
      border-radius: 10px;
      width: 100%;
      height: 4px;
      opacity: 0.5;
      background: $gray-200;
      box-shadow: 2px 2px 2px 0 $gray-500 inset, -2px -2px 6px 0px $white inset;
      margin: map-get($spacers, 3) 0;
    }

    .profile-img {
      text-align: center;
      img {
        max-width: 100px;
        border-radius: 50%;
      }
    }

    .user-edit-details-container {
      display: grid;
      row-gap: 15px;
      grid-template-columns: 20% 20% 20% 20% 20%;
      grid-template-areas:
      "combined-item . . . ."
      "combined-item . . . ."
      "combined-item . . . .";

      .combined-item { grid-area: combined-item; }
      .item {
        padding-left: 10px;
        @include media-breakpoint-up(xl) {
          padding-left: 20px;
        }
      }
    }

    .user-edit-business-details-container {
      display: grid;
      row-gap: 15px;
      grid-template-columns: 20% 20% 20% 20% 20%;
      grid-template-areas:
        "combined-start . . . combined-end"
        "combined-start . . . combined-end"
        "combined-start . . . combined-end";

      .combined-start { grid-area: combined-start; }
      .combined-end { grid-area: combined-end; }
      .item, .combined-end {
        padding-left: 10px;
        @include media-breakpoint-up(xl) {
          padding-left: 20px;
        }
      }
    }
  }

  .added-channel-preview {
    width: 100%;
    display: flex;
    align-items: center;
    padding: map-get($spacers, 2) map-get($spacers, 3);
    border: 0.1px solid rgba(38, 45, 57, 0.4);
    box-shadow: 2px 3px 2px rgba(126, 126, 126, 0.5);
    border-radius: 8px;

    .image,
    .title {
      flex: 0 0 auto;
      max-width: 100%;
    }
    .image {
      width: 62px;
      img {
        max-width: 62px;
        height: auto;
        border-radius: 100%;
      }
    }
    .title {
      padding-left: map-get($spacers, 2);
      padding-right: map-get($spacers, 2);
      flex: 1 0 0;

      h5 {
        display: -webkit-inline-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        width: 100%;
      }
    }
    .connect {
      width: 120px;
      position: relative;
    }
  }

  tabset {
    ul {
      background-color: $gray-100;
      padding-bottom: 1px;
      li.nav-item {
        background-color: $white;
        a.nav-link {
          color: $body-color;
          background-color: $white;
        }
      }
      li.nav-item.active {
        background-color: $gray-100;
        border-bottom: 0;
        a.nav-link.active {
          background-color: $gray-100;
          box-shadow: 2px 2px 2px 0 opacify($gray-500, 0.15) inset, -2px -2px 6px 0px #FFF inset;
        }
      }
    }
  }
}
