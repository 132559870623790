.box-shadow-border {
  box-shadow: inset -2px -2px 6px #FFFFFF, inset 2px 2px 3px #BDC1D1;
  border-radius: $border-radius;
}

.snail-icon, .rabbit-icon, .cat-icon {
  text-indent: -9999px;
}

.snail-icon, .fa-custom-snail {
  display: inline-block;
  width: 22px;
  height: 20px;
  content: url('../assets/images/icons/snail.svg');
  background-size: contain;
}

.rabbit-icon, .fa-custom-rabbit {
  display: inline-block;
  width: 22px;
  height: 20px;
  content: url('../assets/images/icons/rabbit.svg');
  background-size: contain;
}

.cat-icon, .fa-custom-cat {
  display: inline-block;
  width: 30px;
  height: 20px;
  content: url('../assets/images/icons/cat.svg');
  background-size: contain;
}

.form-array-add-button {
  margin-top: 22px;
}

.disabled-menu-item {
  text-decoration: line-through;
  cursor: not-allowed;
}

// Custom DF buttons
.df-btn {
  display: inline-block;
  width: 180px;
  height: 40px;
  padding-top: 11px;
  padding-bottom: 11px;

  border: none;
  background-color: transparent;
  border-radius: 8px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

  font-weight: $font-weight-bold;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;

  @include media-breakpoint-down(xl) {
    font-size: $btn-font-size-lg;
    height: 46px;
    padding-top: 14px;
    padding-bottom: 14px;
    width: 190px;
  }

  &.primary {
    background-color: $primary;
    color: $white;
    transition:
      background-color 0.15s ease-in-out,
      color 0.15s ease-in-out;

    &:hover {
      color: $primary;
      background-color: $white;
    }

    &:disabled, &.disabled {
      background-color: lighten($primary, 10) !important;
      color: $white !important;
    }
  }

  &.secondary {
    background-color: $secondary;
    color: $white;
    transition:
      background-color 0.15s ease-in-out,
      color 0.15s ease-in-out;

    &:hover {
      color: $secondary;
      background-color: $white;
    }
  }

  &.reverse-primary {
    background-color: $light;
    color: $primary;
    transition:
      background-color 0.15s ease-in-out,
      color 0.15s ease-in-out;

    &:hover {
      color: $light;
      background-color: $primary;
    }
  }

  &.reverse-secondary {
    background-color: $light;
    color: $secondary;
    transition:
      background-color 0.15s ease-in-out,
      color 0.15s ease-in-out;

    &:hover {
      color: $light;
      background-color: $secondary;
    }
  }
}
