.editable-cell {
  height: 30px;
  position: relative;

  .edit-button {
    display: none;
    position: absolute;
    right: -15px;
  }

  &:hover {
    .edit-button {
      display: block;
    }
  }
}

.colums-button {
  height: 40px;
  width: 40px;

  button {
    border-radius: 5px;
    height: 30px;
    line-height: unset;

    .mat-button-wrapper {
      line-height: unset !important;
    }
  }
}

datatable-row-wrapper, datatable-header {
  cursor: pointer;
  padding: map-get($spacers, 2);
}

datatable-row-wrapper {
  border-bottom: 1px solid $border-color;

  &:hover {
    background-color: $border-color;
  }
}

datatable-pager {

  .pager {

    li {

      &.active {
        a {
          z-index: 3;
          color: $primary;
          font-weight: $font-weight-bold;
        }
      }

      a {
        position: relative;
        padding: .375rem .75rem;
        display: block;
        color: $body-color;
        text-decoration: none;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
      }
    }
  }
}

datatable-body-row .datatable-row-group {
  align-items: center;
}

.table-preview-image {
  display: block;
  max-width: 40px;
  height: auto;
}
