.form-group.fancy-field {
  label {
    font-weight: $font-weight-bold;
    padding-bottom: map-get($spacers, 2);
  }

  .form-control {
    color: $text-muted;
    box-shadow: 2px 2px 3px 0 $gray-400 inset, -2px -2px 6px 0px $white inset;
    border-radius: calc($input-height / 2) !important;

    &.form-control-sm {
      border-radius: calc($input-height-sm / 2) !important;
    }

    &:focus {
      box-shadow: 2px 2px 1px 0 darken($primary,10) inset, -2px -2px 6px 0px $white inset;
    }

    &.read-only {
      border: none;
      box-shadow: unset !important;
      border-radius: unset;
      padding-left: 0;
      padding-right: 0;
    }
  }
}
