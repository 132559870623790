$font-path: '/assets/fonts/';

@font-face {
  font-family: 'made_tommy';
  src: url($font-path + 'made_tommy_black-webfont.woff2') format('woff2'),
  url($font-path + 'made_tommy_black-webfont.woff') format('woff'),
  url($font-path + 'made_tommy_black.otf') format('otf');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'made_tommy_outline';
  src: url($font-path + 'made_tommy_black_outline-webfont.woff2') format('woff2'),
  url($font-path + 'made_tommy_black_outline-webfont.woff') format('woff'),
  url($font-path + 'made_tommy_black_outline.otf') format('otf');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'made_tommy';
  src: url($font-path + 'made_tommy_bold-webfont.woff2') format('woff2'),
  url($font-path + 'made_tommy_bold-webfont.woff') format('woff'),
  url($font-path + 'made_tommy_bold.otf') format('otf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'made_tommy_outline';
  src: url($font-path + 'made_tommy_bold_outline-webfont.woff2') format('woff2'),
  url($font-path + 'made_tommy_bold_outline-webfont.woff') format('woff'),
  url($font-path + 'made_tommy_bold_outline.otf') format('otf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'made_tommy';
  src: url($font-path + 'made_tommy_extrabold-webfont.woff2') format('woff2'),
  url($font-path + 'made_tommy_extrabold-webfont.woff') format('woff'),
  url($font-path + 'made_tommy_extrabold.otf') format('otf');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'made_tommy_outline';
  src: url($font-path + 'made_tommy_extrabold_outline-webfont.woff2') format('woff2'),
  url($font-path + 'made_tommy_extrabold_outline-webfont.woff') format('woff'),
  url($font-path + 'made_tommy_extrabold_outline.otf') format('otf');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'made_tommy';
  src: url($font-path + 'made_tommy_light-webfont.woff2') format('woff2'),
  url($font-path + 'made_tommy_light-webfont.woff') format('woff'),
  url($font-path + 'made_tommy_light.otf') format('otf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'made_tommy_outline';
  src: url($font-path + 'made_tommy_light_outline-webfont.woff2') format('woff2'),
  url($font-path + 'made_tommy_light_outline-webfont.woff') format('woff'),
  url($font-path + 'made_tommy_light_outline.otf') format('otf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'made_tommy';
  src: url($font-path + 'made_tommy_medium-webfont.woff2') format('woff2'),
  url($font-path + 'made_tommy_medium-webfont.woff') format('woff'),
  url($font-path + 'made_tommy_medium.otf') format('otf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'made_tommy_outline';
  src: url($font-path + 'made_tommy_medium_outline-webfont.woff2') format('woff2'),
  url($font-path + 'made_tommy_medium_outline-webfont.woff') format('woff'),
  url($font-path + 'made_tommy_medium_outline.otf') format('otf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'made_tommy';
  src: url($font-path + 'made_tommy_regular-webfont.woff2') format('woff2'),
  url($font-path + 'made_tommy_regular-webfont.woff') format('woff'),
  url($font-path + 'made_tommy_regular.otf') format('otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'made_tommy_outline';
  src: url($font-path + 'made_tommy_regular_outline-webfont.woff2') format('woff2'),
  url($font-path + 'made_tommy_regular_outline-webfont.woff') format('woff'),
  url($font-path + 'made_tommy_regular_outline.otf') format('otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'made_tommy';
  src: url($font-path + 'made_tommy_thin-webfont.woff2') format('woff2'),
  url($font-path + 'made_tommy_thin-webfont.woff') format('woff'),
  url($font-path + 'made_tommy_thin.otf') format('otf');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'made_tommy_outline';
  src: url($font-path + 'made_tommy_thin_outline-webfont.woff2') format('woff2'),
  url($font-path + 'made_tommy_thin_outline-webfont.woff') format('woff'),
  url($font-path + 'made_tommy_thin_outline.otf') format('otf');
  font-weight: 100;
  font-style: normal;
}

// Fontawesome
@import '../../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome';
@import '../../../node_modules/@fortawesome/fontawesome-free/scss/solid';
@import '../../../node_modules/@fortawesome/fontawesome-free/scss/regular';
@import '../../../node_modules/@fortawesome/fontawesome-free/scss/brands';
@import '../../../node_modules/@fortawesome/fontawesome-free/scss/shims';
