app-tracks-edit {
  .lyrics-textarea textarea {
    min-height: 204px;
  }

  .track-edit-cover {
    width: 200px;
    height: 200px;
    border-radius: map-get($spacers, 3);
  }

  .lyrics-body {
    max-width: 440px;
    width: 100%;
    height: 240px;
    background: #FAFBFF;
    box-shadow: inset -2px -2px 6px #FFFFFF, inset 2px 2px 3px #BDC1D1;
    border-radius: 20px;
    padding: map-get($spacers, 2) map-get($spacers, 3);

    .lyrics-container {
      overflow-y: scroll;
      width: 100%;
      max-height: 220px;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
    .lyrics-container::-webkit-scrollbar {
      display: none;
    }

  }
}
