html,
body {
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.pointer {
  cursor: pointer;
}

.text-underline {
  text-decoration: underline;
}
.btn.disabled,
.btn[disabled] {
  cursor: not-allowed;
}

.main-body {
  position: relative;
}

.main-content-body {
  position: relative;
  z-index: 0;
}

.main-player-body {
  position: fixed;
  bottom: 0;
  z-index: 1001;
}

.btn-clear {
  display: inline-block;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0;
  font-size: 0.9rem;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

// Play Button
.play-button {
  width: 50px;
  height: 50px;
  padding: 0;
  border-radius: 50%;
  border: none;
  background-color: #fff;
  box-shadow: 3px 2px 10px $gray-400;
  color: $text-muted;
  transition: $btn-transition;

  &:hover {
    background-color: $dark;
    color: $light;
  }

  &.active {
    background-color: $primary;
    color: $light;
  }

  &.play-button-small {
    width: 25px;
    height: 25px;
    font-size: 12px;
  }

  &.play-button-list {
    box-shadow: 1px 1px 5px #ced4da;
    margin: 4px;
  }
}

// search bar

$search-bar-input-width: calc(100% - calc(31px + map-get($spacers, 2)));
.light-input-with-button {
  .light-input-button, .light-input {
    display: inline-block;
  }

  .light-input-button {
    background: $white;
    box-shadow: inset -2px -2px 6px $white, inset 2px 2px 3px $gray-500;
    border-radius: 50%;
    border: none;
    padding: 0;

    i {
      padding: map-get($spacers,2);
    }
  }

  .light-input {
    border: none;
    width: calc(100% - calc(31px + map-get($spacers, 2)));
    box-shadow: inset -2px -2px 6px #FFFFFF, inset 2px 2px 3px #BDC1D1;
    border-radius: map-get($spacers, 3);
    padding: map-get($spacers,1) map-get($spacers,2);
  }
}
