.login-page {
  .login-input {
    position: relative;

    label {
      line-height: 100%;
      font-size: 16px;
      font-weight: 500;
    }

    input {
      border: 1px solid #a8abaf;
      font-size: 16px;
      padding-top: map-get($spacers, 2);
      padding-bottom: map-get($spacers, 2);
    }

    .login-placeholder {
      font-size: 16px;
      line-height: 100%;
      position: absolute;
      color: $text-muted;
      font-weight: 500;
      left: 50%;
      bottom: 14px;
      z-index: 1001;
      width: 100%;
      transform: translateX(-50%);
      pointer-events: none;
    }
  }
}
