app-custom-tags-input {
  .custom-tags-input {
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    .label {
      display: inline-block;
      margin-bottom: 2px;
      margin-right: 5px;
      padding-right: 0.3em;

      font-size: $font-size-base * 1.1;

      .remove-btn {
        cursor: pointer;
        display: inline-block;
        margin: -3px 0 0 3px;
        padding: 0;
        vertical-align: top;
        border: none;
        background: transparent;
        color: $white;
      }
    }

    .input-field {
      border: none;
      flex-grow: 1;
      outline: none;
    }
  }
}
