app-tracks-index {
  display: block;
  position: relative;

  .track-collection-filter-container {
    position: absolute;
    left: calc(-250px - 1rem);
    height: 100vh;
    width: 0;
    opacity: 0;
    top: 0;
    display: none;
    padding: 0 $grid-gutter-width * 0.5;
    transition: width .5s ease-in-out, opacity .3s ease-in-out;
    background-color: #ffffff;
    &.visible {
      display: block;
      opacity: 1;
      width: 300px;
    }
  }
}
