app-user-selector {
  .user-button {
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: #fff;

    &.in-focus {
      z-index: 1001;
    }

    &:hover {
      color: #fff;
    }
  }

  ng-autocomplete {
    &.input-error {
      .autocomplete-container .input-container input {
        border-color: $danger;
      }
    }

    .autocomplete-container {
      box-shadow: none;

      .input-container {

        input {
          border-radius: $input-border-radius;
          border: 1px solid $input-border-color;
        }
      }
    }
  }

  ng-autocomplete:focus ~ .user-button, ng-autocomplete:active ~ .user-button {
    z-index: 1001;
  }

}
