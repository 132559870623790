app-playlists-edit {
    .playlist-image {
      width: 210px;
      height: 210px;
      border-radius: map-get($spacers, 3);
    }

    .playlist-image-lg {
      width: 365px;
    }

  .playlist-tracks-list {
    .header {

        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding-bottom: map-get($spacers, 2);
        min-height: 58px;

        & > * {
          display: inline-block;
          max-width: 100%;
        }

        .play-col {
          flex: 0 0 auto;
          width: calc(70px + map-get($spacers, 3));
          padding-right: map-get($spacers, 3);
        }

        .title-col, .artist-col {
          a {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            width: 100%;
            &:hover {
              text-decoration: underline;
            }
            text-decoration: none;
          }
        }

        .title-col {
          flex: 1 0 0;
          padding-right: map-get($spacers, 3);
        }

        .artist-col {
          flex: 0 0 auto;
          width: calc(200px + map-get($spacers, 3));
          padding-right: map-get($spacers, 3);
        }

        .time-col {
          flex: 0 0 auto;
          width: calc(45px + map-get($spacers, 3));
          padding-right: map-get($spacers, 3);
        }

        .genre-col, .emotion-col, .video-col {
          display: none;

          @media(min-width: 1600px) {
            & {
              display: inline-block;
              flex: 0 0 auto;
              width: calc(100px + map-get($spacers, 3));
              padding-right: map-get($spacers, 3);
            }
          }
        }

        .lyrics-col {
          flex: 0 0 auto;
          width: calc(20px + map-get($spacers, 3));
          padding-right: map-get($spacers, 3);
        }

        .tempo-col {
          flex: 0 0 auto;
          width: calc(38px + map-get($spacers, 3));
          padding-right: map-get($spacers, 3);
          text-align: center;

          .cat-icon {
            width: 38px;
            height: auto;
          }
        }

        .published-col {
          flex: 0 0 auto;
          width: calc(100px + map-get($spacers, 3));
          padding-right: map-get($spacers, 3);
        }

        .utility-col {
          flex: 0 0 auto;
          width: 100px;
        }

    }
  }
}
