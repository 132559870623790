.pending-tracks-list {

  .track-item {
    cursor: pointer;
  }
}

.selected-track-info {

  .counter-box {
    display: inline-block;
    border: 0.5px solid $gray-500;
    border-radius: .3rem;
    padding: .15rem .3rem;
    line-height: $font-size-base;
    margin-right: map-get($spacers, 2);

    span {
      display: inline-block;
      min-width: 18px;
      min-height: 18px;
      vertical-align: middle;
      text-align: center;
      font-size: $font-size-base;
      line-height: 18px;
    }
  }

  .form-check-input {
    margin-top: 0;
    width: 1.2rem;
    height: 1.2rem;
    vertical-align: middle;
    background: #EBECF0;
    box-shadow: 1px 1px 7px #EBECF0, inset -5px -4px 10px #FAFBFC, inset 3px 3px 8px #BDC1D1;
    float: none;
    margin-left: map-get($spacers, 1);
    margin-right: map-get($spacers, 1);

    &:checked {
      background-color: $primary;
      border-color: $primary;
      box-shadow: unset;
    }
  }
}
